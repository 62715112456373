<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <form @submit.prevent="handleSubmitBonCMD()">
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">

                                <label for="centre_id" class="mr-sm-2 pt-2"> Centre :</label>

                                            <select class="form-control border rounded" id="centre_id" v-model="itemFormBon.centre_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.centre_id.$error }">

                                            <option v-for="item in centres" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            
                                            <option value=0>Tous</option>

                                            </select>
                                <label for="centre_id" class="mr-sm-2 pt-2 ml-4"> Mois :</label>
                                    <div class="input-group  p-1 col mr-4">
                                                                                <select class="form-control  " id="mois" v-model="itemFormBon.mois"
                                                                                :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.mois.$error }">
                                                                                <option value=1>Janvier</option>
                                                                                <option value=2>Février</option>
                                                                                <option value=3>Mars</option>
                                                                                <option value=4>Avril</option>
                                                                                <option value=5>Mai</option>
                                                                                <option value=6>Juin</option>
                                                                                <option value=7>Juillet</option>
                                                                                <option value=8>Août</option>
                                                                                <option value=9>Septembre</option>
                                                                                <option value=10>Octobre</option>
                                                                                <option value=11>Novembre</option>
                                                                                <option value=12>Décembre</option>
                                                                                </select>
                                    </div>

        

      



                                    <div class="input-group-prepend">
                                        <button class="btn  btn-info rounded-pill ml-1" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                                 <div class="input-group-prepend">
                                    <b-button v-if="itemslist.length > 0" pill size="sm"  class="m-3 p-2 float-right" variant="success" @click="bonsPrint_click">
                                        <i class="fa fa-print"></i>
                                        <span> Imprimer La Liste Des Reçus</span> 
                                    </b-button>
                                </div>

                </div>
        </form>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    



            <div class="col">
            <h5 class="float-left ml-3 mt-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Paiements Agriculteurs </h5> 

           <h5 class="float-left ml-5 mt-3 font-weight-lighter text-secondary">  Total Lait : {{total_l}} DH</h5> 
            <h5 class="float-left ml-4 mt-3 font-weight-lighter text-secondary">  Total Service / Crédit : {{total_s}} DH</h5> 
            <h5 class="float-left ml-4 mt-3 font-weight-lighter text-success">  Total Net : {{total_n}} DH</h5> 

                                    <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="exportDox">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>






                    </div>
            </div>
       



        <b-table 
        id="main-table"
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        :per-page="perPage"
        :current-page="currentPage"
        >
      
        </b-table>

                        <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {
    rows_pagin() {
        return this.itemslist.length
      },
    total_l: function() {
      return this.itemslist.reduce((a, b) => +a + +b.total_lait, 0);
    },

      total_s: function() {
      return this.itemslist.reduce((a, b) => +a + +b.total_service, 0);
    },
      total_n: function() {       
    return Number(this.itemslist.reduce((a, b) => +a + +b.total_net, 0)).toFixed(2);

    },


    },
    mounted(){
      this.fetchCentre()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    centre_id:'',
                    mois:'',
                    mois_nom:'',
                    mois_nomAR:''
            },

            centres:[],
            itemslist: [],
            fields: [
				{key: 'cin', label: 'CIN/ID', sortable: true},
                {key: 'nom', label: 'Nom & Prénom', sortable: true},
                {key: 'nom_ar', label: 'Nom & Prénom AR', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'total_lait', label: 'Lait', sortable: true},
                {key: 'total_service', label: 'Services/Crédit', sortable: true},
                {key: 'total_net', label: 'Net', sortable: true},
            ],
            printlista:[],
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                centre_id: {
                    required
                },
                mois: {
                    required
                }
            }

    },
    methods :{
        fetchCentre: function () {


                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/centres')
                    .then((result) => {
                                this.centres = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },

        bonsPrint_click: function () {

if(this.itemFormBon.centre_id && this.itemFormBon.centre_id >0 && this.itemFormBon.mois)
{
        //rechercher le nom du mois
        this.getMoisName(this.itemFormBon.mois)


        let mm=this.centres.findIndex(item => item.id === this.itemFormBon.centre_id)

       let cent_nom= this.centres[mm].nom



                           this.isLoading=true;
                    this.$http.get('/paiemAgrPrintGlob/'+this.itemFormBon.centre_id+'/'+this.itemFormBon.mois+'/'+this.itemFormBon.mois_nomAR+'/'+cent_nom)
                    .then((result) => {
                                this.printlista = result.data.data
                                this.isLoading=false;

                                 this.exportListaPrintDox(cent_nom)






                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });

}
else
{
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
}

            

        },



        handleSubmitBonCMD(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchList(this.itemFormBon.centre_id,this.itemFormBon.mois)
   

                }


        },

        fetchList: function (idcntr,ms) {
                    this.isLoading=true;
                    this.$http.get('/listPaimAgriRapp/'+idcntr+'/'+ms)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        exportDox()
        {

        //rechercher le nom du mois
        this.getMoisName(this.itemFormBon.mois)


        let cent = ""

        if(this.itemFormBon.centre_id==0)
        {
        cent="Tous"
        }
        else
        {
        let mm=this.centres.findIndex(item => item.id === this.itemFormBon.centre_id)

        cent= this.centres[mm].nom
        }



        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "CIN/ID",
                                            spacing: {
                before: 100,
                after: 100
            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Nom-Prénom",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Nom-Prénom AR",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Date",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Lait",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Services/Crédits",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Total Net",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })]

        this.itemslist.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.cin,
                                                                                spacing: {
                before: 50,
                after: 50
            },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.nom,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.nom_ar,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.date,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.total_lait.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.total_service.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.total_net.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),







                    ],
                })

        datrw.push(
        r
        )
        });

                const doc = new Document();

        const table = new Table({

            rows:  datrw
        })



                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Paiements Agriculteurs",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                        
                        
                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "     Centre : "+cent + "            Mois : " + this.itemFormBon.mois_nom,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),

                        new Paragraph({



                            children: [
                                new TextRun({
                                text: "Total Lait : "+this.total_l+" DH              Total Services/Crédits : "+this.total_s+" DH",
                                color: '636572',
                                bold: false,
                            }),
                            new TextRun({
                                text: "      Total Net : "+this.total_n +" DH",
                                color: 'db8932',
                                bold: true,
                            })
                            ],

                        
                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    after: 300
                                },
                        }),
                        table
                        
                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "PaiemAgris.docx");

            });

        },


        getMoisName(mm)
        {
                        if (mm==1)
                        {
                        this.itemFormBon.mois_nom='Janvier'

                        this.itemFormBon.mois_nomAR='يناير'
                        }
                        else if (mm==2)
                        {
                        this.itemFormBon.mois_nom='Février'
                        this.itemFormBon.mois_nomAR='فبراير'
                        }
                        else if (mm==3)
                        {
                        this.itemFormBon.mois_nom='Mars'

                        this.itemFormBon.mois_nomAR='مارس'
                        }
                        else if (mm==4)
                        {
                        this.itemFormBon.mois_nom='Avril'

                        this.itemFormBon.mois_nomAR='ابريل'
                        }
                        else if (mm==5)
                        {
                        this.itemFormBon.mois_nom='Mai'

                        this.itemFormBon.mois_nomAR='ماي'
                        }
                        else if (mm==6)
                        {
                        this.itemFormBon.mois_nom='Juin'

                        this.itemFormBon.mois_nomAR='يونيو'
                        }
                        else if (mm==7)
                        {
                        this.itemFormBon.mois_nom='Juillet'

                        this.itemFormBon.mois_nomAR='يليوز'
                        }
                        else if (mm==8)
                        {
                        this.itemFormBon.mois_nom='Août'

                        this.itemFormBon.mois_nomAR='غشت'
                        }
                        else if (mm==9)
                        {
                        this.itemFormBon.mois_nom='Septembre'

                        this.itemFormBon.mois_nomAR='شتنبر'
                        }
                        else if (mm==10)
                        {
                        this.itemFormBon.mois_nom='Octobre'

                        this.itemFormBon.mois_nomAR='اكتوبر'
                        }
                        else if (mm==11)
                        {
                        this.itemFormBon.mois_nom='Novembre'

                        this.itemFormBon.mois_nomAR='نونبر'
                        }
                        else if (mm==12)
                        {
                        this.itemFormBon.mois_nom='Décembre'

                        this.itemFormBon.mois_nomAR='دجنبر'
                        }
                        else
                        {
                        this.itemFormBon.mois_nom='--';
                        this.itemFormBon.mois_nomAR='--'
                        }

        },
//la liste :

    exportListaPrintDox(ncent)
    {
        const doc = new Document();


this.printlista.forEach(function(aginfos){
                
        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "المجموع",
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "الثمن",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "الكمية",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })]

        //داخل الكوطة
        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: ""+aginfos.data_laitquotaDH.toString(),
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: aginfos.data_prixquota.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: aginfos.data_laitquotaL.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "داخل الكوطا",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )

        //فوق الكوطة
        r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: ""+aginfos.data_laitsurquotaDH,
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: aginfos.data_prixsurquota.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: aginfos.data_laitsurquotaL.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "فوق الكوطا",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(r)

        //les tauteaooo
        r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: ""+aginfos.global_laitdh,
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "المستحق",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: ""+aginfos.global_laitL,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "مجموع الكمية ",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(r)

        const table = new Table({

            rows:  datrw
        })


//listes des services:

                        let datrwServ =[new TableRow({
                                    children: [

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "المجموع بالدرهم",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "مجموع الكمية",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 4600,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "الخدمة",
                                                            spacing: {
                                                                before: 100,
                                                                after: 100
                                                            },
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),



                                    ],
                                })]

                        aginfos.services_list.forEach(function(item){

                        let rr = new TableRow({
                                    children: [

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.total_dh.toString(),
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.total_qte.toString(),
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 4600,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.nom,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                    ],
                                })

                        datrwServ.push(
                        rr
                        )
                        });

//total services :
                        let rrtotal = new TableRow({
                                    children: [

                                        new TableCell({
                                            width: {
                                                size: 4000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [                        new Paragraph({

                            bidirectional: true,

                            children: [

                            new TextRun({
                                text: "المجموع ",
                                color: '636572',
                                bold: true,
                            }),

                            new TextRun({
                                text:  aginfos.data_total_s+" : ",
                                color: '636572',
                                bold: true,
                            }),


                            
                            ],

                        
                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),],
                                        }),

                                    ]
                                })


                        datrwServ.push(
                        rrtotal
                        )


                        const tableserv = new Table({

                            rows:  datrwServ
                        })

//fin des liste des  services

                        doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({

                            bidirectional: true,

                            children: [

                                new TextRun({
                                text: "وصل الأداء لشهر :",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                                new TextRun({
                                text: aginfos.mois_n,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })
                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                        
                        
                        new Paragraph({

                            bidirectional: true,

                            children: [

                                new TextRun({
                                text: "السيد/السيدة",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                            new TextRun({
                                text: " : "+aginfos.nomar_agr ,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                            new TextRun({
                                text: "Centre : " + aginfos.centre_n,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                            new TextRun({
                                text: "    CIN : " + aginfos.cinagr,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                            new TextRun({
                                text: "       "+aginfos.nomagr+"      ",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })

                            ],

                            heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),




                        new Paragraph({

                            bidirectional: true,

                            children: [

                            new TextRun({
                                text: "الحليب",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                        
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    before: 200
                                },
                        }),

                        table,


                        new Paragraph({

                            bidirectional: true,

                            children: [

                            new TextRun({
                                text: "الخدمات",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                        
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    before: 200
                                },
                        }),


                        tableserv,


                        new Paragraph({

                            bidirectional: true,

                            children: [





                            new TextRun({
                                text: "مجموع التسبيق"  ,
                                color: '636572',
                                bold: true,
                            }),

                            new TextRun({
                                text: aginfos.data_total_avanc +" : ",
                                color: '636572',
                                bold: true,
                            }),
                            
                            ],

                        
                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.LEFT,
                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                                        new Paragraph({

                            bidirectional: true,

                            children: [





                            new TextRun({
                                text: "الدين القديم"  ,
                                color: '636572',
                                bold: true,
                            }),

                            new TextRun({
                                text:  aginfos.data_ancien +" : ",
                                color: '636572',
                                bold: true,
                            }),
                            
                            ],

                        
                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.LEFT,
                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),

                        

                        new Paragraph({

                            bidirectional: true,

                            children: [

                            new TextRun({
                                text: "المستحق الصافي بالدرهم",
                                color: 'db8932',
                                bold: true,
                                font: "Segoe UI"
                            }),

                            new TextRun({
                                text: aginfos.netpaye+" : ",
                                color: 'db8932',
                                bold: true,
                                font: "Segoe UI"
                            }),

                            
                            
                            ],

                        
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    after: 300
                                },
                        }),

                        ]
                })



});








            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "List_reçu_centre_"+ncent+".docx");

            });

        },

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>