<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <form @submit.prevent="handleSubmitBonCMD()">
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">

                                <label for="banq_id" class="mr-sm-2 pt-2"> Banque :</label>

                                            <select class="form-control border rounded" id="banq_id" v-model="itemFormBon.banq_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.banq_id.$error }">

                                            <option v-for="item in banqs" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            </select>
                                <label for="banq_id" class="mr-sm-2 pt-2 ml-4"> Mois :</label>
                                    <div class="input-group  p-1 col mr-4">
                                                                                <select class="form-control  " id="mois" v-model="itemFormBon.mois"
                                                                                :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.mois.$error }">
                                                                                <option value=1>Janvier</option>
                                                                                <option value=2>Février</option>
                                                                                <option value=3>Mars</option>
                                                                                <option value=4>Avril</option>
                                                                                <option value=5>Mai</option>
                                                                                <option value=6>Juin</option>
                                                                                <option value=7>Juillet</option>
                                                                                <option value=8>Août</option>
                                                                                <option value=9>Septembre</option>
                                                                                <option value=10>Octobre</option>
                                                                                <option value=11>Novembre</option>
                                                                                <option value=12>Décembre</option>
                                                                                </select>
                                    </div>

        

      



                                    <div class="input-group-prepend">
                                        <button class="btn  btn-info rounded-pill ml-1" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                                

                </div>
        </form>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    



            <div class="col">
            <h5 class="float-left ml-3 mt-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Paiements Agriculteurs Par Banque </h5> 

           <h5 class="float-left ml-5 mt-3 font-weight-lighter text-secondary">  Total Lait : {{total_l}} DH</h5> 
            <h5 class="float-left ml-4 mt-3 font-weight-lighter text-secondary">  Total Service / Crédit : {{total_s}} DH</h5> 
            <h5 class="float-left ml-4 mt-3 font-weight-lighter text-success">  Total Net : {{total_n}} DH</h5> 

                                    <b-button v-if="itemslist.length > 0" pill size="sm"  class="m-3 float-right" variant="success" @click="fetchFormComptes_valider">
                                        <i class="fa fa-check"></i>
                                        <span> Valider</span> 
                                    </b-button>

                    </div>
            </div>
       



        <b-table 
        id="main-table"
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        :per-page="perPage"
        :current-page="currentPage"
        >
      
        </b-table>

                        <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
      </div>




    <!-- le modal -->
    <b-modal id="validerModal" title="Le paiement" hide-footer>
        <form @submit.prevent="handleSubmitReglerBn">

          <div class="mt-3 ml-2  text-secondary font-weight-light">Total Net (DH) : 
          <span class="d-inline text-info"> {{total_n}} </span>  </div>

            <div class="form-group">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="itemVal.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>




            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block mt-4">Valider</button>
            </div>
        </form>
    </b-modal>



    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";


export default {
      computed: {

    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
      rows_pagin() {
        return this.itemslist.length
      },
    total_l: function() {
      return this.itemslist.reduce((a, b) => +a + +b.data_LaitDH, 0);
    },

      total_s: function() {
      return this.itemslist.reduce((a, b) => +a + +b.data_serv_avnc, 0);
    },
      total_n: function() {  
          let res = this.itemslist.filter(it => it.netpaye >0);     
    return res.reduce((a, b) => +a + +b.netpaye, 0);

    },


    },
    mounted(){
      this.fetch_banques()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    banq_id:'',
                    banq_nom:'',
                    mois:'',
                    mois_nom:''
            },
            itemVal: {
                date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                user:'user',
                bank_compte_id:'',
                    },
            banqs:[],
            comptes:[],
            itemslist: [],
            fields: [
				{key: 'cinagr', label: 'CIN/ID', sortable: true},
                {key: 'nomagr', label: 'Nom & Prénom', sortable: true},
                {key: 'data_laitquota', label: 'Quota(L)', sortable: true},
                {key: 'data_laitsurquota', label: 'Sur Quota(L)', sortable: true},
                {key: 'data_LaitDH', label: 'Lait DH', sortable: true},
                {key: 'data_serv_avnc', label: 'Services/Avances', sortable: true},
                {key: 'data_ancien', label: 'Ancien Crédit', sortable: true},
                {key: 'netpaye', label: 'Net', sortable: true},
            ],

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                banq_id: {
                    required
                },
                mois: {
                    required
                }
            }

    },
    methods :{

        fetchFormComptes_valider: function () {
            //comptes :
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;

                                 this.$root.$emit('bv::show::modal', 'validerModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },


        fetch_banques: function () {


                    this.isLoading=true;
                    this.$http.get('/banques')
                    .then((result) => {
                                this.banqs = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },

        handleSubmitBonCMD(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchList(this.itemFormBon.banq_id,this.itemFormBon.mois)
   

                }


        },

        fetchList: function (idbanq,ms) {
                    this.isLoading=true;
                    this.$http.get('/paiemAgrClclMoisList/'+idbanq+'/'+ms)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        getMoisName(mm)
        {
                        if (mm==1)
                        {
                        this.itemFormBon.mois_nom='Janvier'

                        }
                        else if (mm==2)
                        {
                        this.itemFormBon.mois_nom='Février'

                        }
                        else if (mm==3)
                        {
                        this.itemFormBon.mois_nom='Mars'

                        }
                        else if (mm==4)
                        {
                        this.itemFormBon.mois_nom='Avril'

                        }
                        else if (mm==5)
                        {
                        this.itemFormBon.mois_nom='Mai'

                        }
                        else if (mm==6)
                        {
                        this.itemFormBon.mois_nom='Juin'

                        }
                        else if (mm==7)
                        {
                        this.itemFormBon.mois_nom='Juillet'

                        }
                        else if (mm==8)
                        {
                        this.itemFormBon.mois_nom='Août'


                        }
                        else if (mm==9)
                        {
                        this.itemFormBon.mois_nom='Septembre'

                        }
                        else if (mm==10)
                        {
                        this.itemFormBon.mois_nom='Octobre'


                        }
                        else if (mm==11)
                        {
                        this.itemFormBon.mois_nom='Novembre'

                        }
                        else if (mm==12)
                        {
                        this.itemFormBon.mois_nom='Décembre'

                        }
                        else
                        {
                        this.itemFormBon.mois_nom='--';

                        }

        },


        handleSubmitReglerBn()
        {

              if(!this.itemVal.bank_compte_id)
              {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })   
              }
            else
            {

                    //USER HUNDEL
                    this.itemVal.user= this.user_name
                    this.getMoisName(this.itemFormBon.mois)

                    //charger le nom de la bank
                    let mm=this.banqs.findIndex(item => item.id === this.itemFormBon.banq_id)
                    let nom_bq= this.banqs[mm].nom
  
                    //procedure du modification
                                this.isLoading=true;

                                this.$http.post('/validerBankPaiemAgrilMois',{
                                date:this.itemVal.date,  
                                mois:this.itemFormBon.mois,
                                id_bank:this.itemFormBon.banq_id,
                                n_mois: this.itemFormBon.mois_nom,
                                user:this.itemVal.user,
                                bank_compte_id:this.itemVal.bank_compte_id,
                                nom_bank:nom_bq
                                    })
                                    .then(response => {

                                        this.isLoading=false;
                                        console.log(response.data)
                                        this.$bvModal.hide('validerModal')


//recharger le truk
                this.fetchList(this.itemFormBon.banq_id,this.itemFormBon.mois)


 
                                        this.flashMessage.show({
                                        status: 'success',
                                        title: 'Données modifiées',
                                        message: 'Les données sont modifiées correctement'
                                        })


                                        })
                                        .catch(error=> {console.log(error)
                                        this.isLoading=false;


                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur De Modification',
                                        message: 'Impossible  de modifier les données .. Réessayez !!'
                                    })
                                    });
                }




        },


    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>