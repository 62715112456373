<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-clipboard-list fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Rapports Finance</h2>
</div>


<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_compt">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Soldes Des Comptes</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list  fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>

<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_four">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Paiements Fournisseurs En Retard</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-coins fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

                        <!-- Earnings (Monthly) Card Example  --> 
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_client">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Paiements Clients / Vendeurs En Retard</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-coins fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>


<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_Valider_paybanques">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-primary text-uppercase">1-Valider Paiements Agriculteurs Par Banque</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-house-user fa-2x text-primary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Monthly) Card Example  --> 
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_ordre_paie">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-info  text-uppercase ">2-Ordre De Paiements Des Agriculteurs Par Banque</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-user-tag fa-2x text-info"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>


<div class=" row p-4">



                        <!-- Earnings (Monthly) Card Example  --> 
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_paiemagri">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-success  text-uppercase "> 3-Paiements Agriculteurs Validés/ Impression Des reçus</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-user-check fa-2x text-success"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


                        <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_impAgri">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-danger text-uppercase">4-Agriculteurs Impayés</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-user-clock fa-2x text-danger"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>



<div v-if="d_gst_trait_salaire==true" class=" row p-4">



                        <!-- Earnings (Monthly) Card Example  --> 
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_salaire">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-success  text-uppercase "> Liste Des Salaires Des Employés</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-user-check fa-2x text-success"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>

  </div>
</template>

<script>
export default { 

    computed: {
      d_gst_trait_salaire() {
      return this.$store.getters.get_gst_trait_salaire;
    },
    },
 methods:{
       click_four()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'paiemFourRetard'

                    });
      },
      click_client()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'paiemClitVendRetr'

                    });
      },
      click_compt()
      {
                       //goto first page: 
                    this.$router.push({ 
                        name: 'listSoldesCompteRapp'

                    });
      },
      click_impAgri()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'listimpagriRapp'

                    });
      },
      click_paiemagri()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'paiemAgriRapp'

                    });
      },
      click_Valider_paybanques()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'agriBankListVal'

                    });
      },
      click_ordre_paie() 
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'agriOrderPaie'

                    });
      },
      click_salaire() 
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'salairesListRapp'

                    });
      },

 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>