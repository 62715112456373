<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <form @submit.prevent="handleSubmitBonCMD()">
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">

                                <label for="centre_id" class="mr-sm-2 pt-2"> Centre :</label>

                                            <select class="form-control border rounded" id="centre_id" v-model="itemFormBon.centre_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.centre_id.$error }">

                                            <option v-for="item in centres" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            
                                            <option value=0>Tous</option>

                                            </select>


                                <label for="is_matin" class="mr-sm-2  pt-2 pl-1"> Période :</label>

                                            <select class="form-control border rounded" id="is_matin" v-model="itemFormBon.is_matin"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.is_matin.$error }">
                                            <option value=1>Matin</option>
                                            <option value=0>Soir</option>
                                            <option value=2>Matin et Soir</option>
                                            </select>

        
                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> De :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
        

                       
                <label for="dateFin" class="mr-sm-2  pt-2 pl-1"> Au :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="itemFormBon.dateFin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
      



                                    <div class="input-group-prepend">
                                        <button class="btn  btn-info rounded-pill ml-1" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                                

                </div>
        </form>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    



                    <div class="col">
            <h5 class="float-left ml-3 mt-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Liste Du Lait collecté</h5> 
            
            <h5 class="float-left ml-5 mt-3 font-weight-lighter text-secondary">  Total Quota : {{total_quota}} L</h5> 
            <h5 class="float-left ml-4 mt-3 font-weight-lighter text-secondary">  Total Sur Quota : {{total_surquota}} L</h5> 
            <h5 class="float-left ml-4 mt-3 font-weight-lighter text-success">  Total : {{total_general}} L</h5> 
                                    <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="exportDox">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>
            </div>
       



        <b-table 

        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"


        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
      
        </b-table>

                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {

      rows_pagin() {
        return this.itemslist.length
      },
      total_quota: function() {
      return this.itemslist.reduce((a, b) => +a + +b.quota, 0);
    },

      total_surquota: function() {
      return this.itemslist.reduce((a, b) => +a + +b.surquota, 0);
    },
      total_general: function() {
      return this.total_quota + this.total_surquota;
    },

    },
    mounted(){
      this.fetchCentre()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    centre_id:'',
                    centre_nom:'',
                    dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    dateFin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    is_matin:''
            },

            centres:[],
            itemslist: [],
            fields: [
				{key: 'cin', label: 'CIN/ID', sortable: true},
                {key: 'nom', label: 'Nom & Prénom', sortable: true},
                {key: 'quota', label: 'Quota', sortable: true},
                {key: 'surquota', label: 'Sur Quota', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'is_matin', label: 'Période', 
                formatter: (value) => {
                 return value ? 'Matin' : 'Soir'
                }, sortable: true},
                {key: 'description', label: 'Description', sortable: true},
            ],

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                centre_id: {
                    required
                },
                is_matin: {
                    required
                },
                dateDebut: {
                    required
                },
                dateFin: {
                    required
                }
            }

    },
    methods :{
                fetchCentre: function () {


                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/centres')
                    .then((result) => {
                                this.centres = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },

                handleSubmitBonCMD(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchList(this.itemFormBon.centre_id,this.itemFormBon.is_matin,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)
   

                }


        },

        fetchList: function (idcntr,is_mat,deb,fin) {
                    this.isLoading=true;
                    this.$http.get('/parmLaiListDate/'+idcntr+'/'+is_mat+'/'+deb+'/'+fin)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        exportDox()
        {
        let matin=""
        let cent = ""

        if(this.itemFormBon.is_matin==0)
        {
        matin="Soir"
        }
        else if(this.itemFormBon.is_matin==1)
        {
        matin="Matin"
        }
        else
        {
        matin="Matin et Soir"
        }

        if(this.itemFormBon.centre_id==0)
        {
        cent="Tous"
        }
        else
        {
        let mm=this.centres.findIndex(item => item.id === this.itemFormBon.centre_id)

        cent= this.centres[mm].nom
        }



        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "CIN/ID",
                                            spacing: {
                before: 100,
                after: 100
            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Nom Et Prénom",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Quota",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Sur Quota",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Date",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Description",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                    ],
                })]

        this.itemslist.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.cin,
                                                                                spacing: {
                before: 50,
                after: 50
            },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.nom,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.quota.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.surquota.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.date,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.description,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                    ],
                })

        datrw.push(
        r
        )
        });

                const doc = new Document();

        const table = new Table({

            rows:  datrw
        })



                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Liste Du Lait collecté",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                                new TextRun({
                                text: "     De : "+this.itemFormBon.dateDebut+" Au "+this.itemFormBon.dateFin,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })
                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                        
                        
                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "     Centre : "+cent+"         Périod : "+matin,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),


                        new Paragraph({



                            children: [
                                new TextRun({
                                text: "Total Quota : "+this.total_quota+" L              Total Sur Quota : "+this.total_surquota+" L",
                                color: '636572',
                                bold: false,
                            }),
                            new TextRun({
                                text: "      Total : "+this.total_general +" L",
                                color: 'db8932',
                                bold: true,
                            })
                            ],

                        
                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    after: 300
                                },
                        }),

                        table
                        
                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "lait_collecté.docx");

            });

        }


    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>