<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-cogs fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Production/ Transformation</h2>
</div>




<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_resume">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Résumé De La Production</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-cogs fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Annual) Card Example  -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_processus">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Processus ( Transformation )</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-microchip fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
</div>

<div class=" row p-4">

              <!-- Earnings (Annual) Card Example  -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_prod_fini">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Produits Finis</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Earnings (Annual) Card Example  -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_sorties">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Détails Des Sorties / Produits</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
</div>



<div class=" row p-4">

                        <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_reception">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Réceptions / Sorties De La Matière Première</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>




</div>




  </div>
</template>

<script>
export default {

 methods:{
      click_resume()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'rappProdProcResum'

                    });
      },
      click_processus()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'rappProdProcess'

                    });
      },
       click_prod_fini()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'rappProdProduitFini'

                    });
      },
      click_sorties()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'rappProdSorties'

                    });
      },
      click_reception()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'rappProdReception'

                    });
      },



 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>