<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <div>
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">


        
                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> Date D'Echéance  De :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
        

                       
                <label for="dateFin" class="mr-sm-2  pt-2 pl-1"> Au :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="itemFormBon.dateFin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
      




                </div>



                <div class=" d-flex justify-content-center text-info mb-4">



                                        <button class="btn  btn-info rounded-pill m-2" @click="handleSubmitBonCMD(1)" >   
                                        <span class="fa fa-search"></span> Paiements Fournisseurs Réglés
                                        </button>


                                

                                        <button class="btn  btn-secondary rounded-pill m-2" @click="handleSubmitBonCMD(0)" >   
                                        <span class="fa fa-search"></span> Fournisseurs Impayés
                                        </button>

                                        <button class="btn  btn-info rounded-pill m-2" @click="handleSubmitGarages(1)" >   
                                        <span class="fa fa-search"></span> Paiements Garages Réglés
                                        </button>


                                

                                        <button class="btn  btn-secondary rounded-pill m-2" @click="handleSubmitGarages(0)" >   
                                        <span class="fa fa-search"></span> Garages Impayés
                                        </button>




                </div>




        </div>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    



                    <div class="col">
            <h5 class="float-left ml-3 mt-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span>   {{typebn}}</h5> 
            
            <h5 class="float-left ml-5 mt-3 font-weight-lighter text-secondary">  Total : {{total_mm}}</h5> 

                                    <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="exportDox">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>
            </div>
       



        <b-table 

        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"


        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
      
        </b-table>

                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {

      rows_pagin() {
        return this.itemslist.length
      },
      total_mm: function() {
      return (this.itemslist.reduce((a, b) => +a + +b.total_montant, 0)).toFixed(2);
    },

    },
    mounted(){

    },
	data () {
		return {
            typebn:'',
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    dateFin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            },


            itemslist: [],
            fields: [
				{key: 'nom', label: 'Fournisseur', sortable: true},
                {key: 'total_montant', label: 'Montant', sortable: true},

            ],

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                dateDebut: {
                    required
                },
                dateFin: {
                    required
                }
            }

    },
    methods :{
                handleSubmitGarages(eta){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                if(eta==1)
                {
                this.typebn = "Résumé Des Paiements Garages"   
                }
                else if(eta==0)
                {
                this.typebn = "Paiements Garages Impayés"   

                }
                else
                {
                this.typebn = ""   

                }


                this.fetchListGarage(eta,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)
   

                }


        },

        fetchListGarage: function (etat,deb,fin) {
                    this.isLoading=true;
                    this.$http.get('/listResumPaiemGaragesRapp/'+etat+'/'+deb+'/'+fin)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },


                handleSubmitBonCMD(eta){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                if(eta==1)
                {
                this.typebn = "Résumé Des Paiements Fournisseurs"   
                }
                else if(eta==0)
                {
                this.typebn = "Paiements Fournisseurs Impayés"   

                }
                else
                {
                this.typebn = ""   

                }


                this.fetchList(eta,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)
   

                }


        },

        fetchList: function (etat,deb,fin) {
                    this.isLoading=true;
                    this.$http.get('/listResumPaiemFournsRapp/'+etat+'/'+deb+'/'+fin)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        exportDox()
        {




        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Fournisseur",
                                            spacing: {
                before: 100,
                after: 100
            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Montant",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })]

        this.itemslist.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.nom,
                                                spacing: {
                                                before: 50,
                                                after: 50
                                            },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.total_montant.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )
        });

                const doc = new Document();

        const table = new Table({

            rows:  datrw
        })



                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: this.typebn,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                        



                        new Paragraph({



                            children: [

                                 new TextRun({
                                text: " Date D'Echéance De : "+this.itemFormBon.dateDebut+" Au "+this.itemFormBon.dateFin + "      Total : "+this.total_mm,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),




                        table
                        
                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "Resumé_paiment four.docx");

            });

        }


    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>