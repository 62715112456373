<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <form @submit.prevent="handleSubmitBonCMD()">
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">

                                <label for="banq_id" class="mr-sm-2 pt-2"> Banque de l'employé :</label>

                                            <select class="form-control border rounded" id="banq_id" v-model="itemFormBon.banq_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.banq_id.$error }">

                                            <option v-for="item in banqs" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}   
                                            </option>

                                            <option value=0>Tous</option>
                                            </select>
                                <label for="banq_id" class="mr-sm-2 pt-2 ml-4"> Mois :</label>
                                    <div class="input-group  p-1 col mr-4">
                                                                                <select class="form-control  " id="mois" v-model="itemFormBon.mois"
                                                                                :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.mois.$error }">
                                                                                <option value=1>Janvier</option>
                                                                                <option value=2>Février</option>
                                                                                <option value=3>Mars</option>
                                                                                <option value=4>Avril</option>
                                                                                <option value=5>Mai</option>
                                                                                <option value=6>Juin</option>
                                                                                <option value=7>Juillet</option>
                                                                                <option value=8>Août</option>
                                                                                <option value=9>Septembre</option>
                                                                                <option value=10>Octobre</option>
                                                                                <option value=11>Novembre</option>
                                                                                <option value=12>Décembre</option>
                                                                                </select>
                                    </div>
                                    
                                    <div class="input-group-prepend">
                                        <button class="btn  btn-info rounded-pill ml-1" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                                

                </div>
        </form>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    



            <div class="col">
            <h5 class="float-left ml-3 mt-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Liste Des Salaires </h5> 


            <h5 class="float-left ml-4 mt-3 font-weight-lighter text-success">  Total : {{total_n}} DH</h5> 

                                    <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="exportDox">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>


                    </div>
            </div>
       



        <b-table 
        id="main-table"
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        :per-page="perPage"
        :current-page="currentPage"
        >
      
        </b-table>

                        <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
      </div>






    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {

    rows_pagin() {
        return this.itemslist.length
      },
      total_n: function() {  
          return this.itemslist.reduce((a, b) => +a + +b.salaire_net, 0).toFixed(2);
    },


    },
    mounted(){
      this.fetch_banques()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    banq_id:'',
                    banq_nom:'',
                    mois:'',
                    mois_nom:''
            },
            banqs:[],
            itemslist: [],
            fields: [
                {key: 'code', label: 'Code/ CIN', sortable: true},
                {key: 'nom_prenom', label: 'Nom & Prénom', sortable: true},
                {key: 'salaire_net', label: 'Salaire Net', sortable: true},
                {key: 'rib', label: 'N° RIB', sortable: true},
                {key: 'user', label: 'Utilisateur', sortable: true},
            ],

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                banq_id: {
                    required
                },
                mois: {
                    required
                }
            }

    },
    methods :{



        fetch_banques: function () {


                    this.isLoading=true;
                    this.$http.get('/banques')
                    .then((result) => {
                                this.banqs = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },

        handleSubmitBonCMD(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchList(this.itemFormBon.banq_id,this.itemFormBon.mois)
   

                }


        },

        fetchList: function (idbanq,ms) {
                    this.isLoading=true;
                    this.$http.get('/listSalaires/'+ms+'/'+idbanq)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        exportDox()
        {

        //rechercher le nom du mois
        this.getMoisName(this.itemFormBon.mois)

let nom_bq = "Tous"

if(this.itemFormBon.banq_id > 0)
{
        let mm=this.banqs.findIndex(item => item.id === this.itemFormBon.banq_id)

        nom_bq= this.banqs[mm].nom
}



        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Code / CIN",
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Nom-Prénom",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Salaire Net",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "N° RIB",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })]

        this.itemslist.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.code,
                                        spacing: {
                                            before: 50,
                                            after: 50
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.nom_prenom,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.salaire_net.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.rib,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )
        });

                const doc = new Document();

        const table = new Table({

            rows:  datrw
        })



                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Liste Des Salaires",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                        
                        
                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "     Banque : "+nom_bq + "            Mois : " + this.itemFormBon.mois_nom,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),

                        table,
                        
                        new Paragraph({

                            children: [

                            new TextRun({
                                text: "Total : ",
                                color: 'db8932',
                                bold: true,
                                font: "Segoe UI"
                            }),

                            new TextRun({
                                text: this.total_n+" DH",
                                color: 'db8932',
                                bold: true,
                                font: "Segoe UI"
                            }),

                            
                            
                            ],

                        
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    before: 300
                                },
                        }),
                        
                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "Liste_salaire_"+nom_bq+".docx");

            });

        },
        getMoisName(mm)
        {
                        if (mm==1)
                        {
                        this.itemFormBon.mois_nom='Janvier'

                        }
                        else if (mm==2)
                        {
                        this.itemFormBon.mois_nom='Février'

                        }
                        else if (mm==3)
                        {
                        this.itemFormBon.mois_nom='Mars'

                        }
                        else if (mm==4)
                        {
                        this.itemFormBon.mois_nom='Avril'

                        }
                        else if (mm==5)
                        {
                        this.itemFormBon.mois_nom='Mai'

                        }
                        else if (mm==6)
                        {
                        this.itemFormBon.mois_nom='Juin'

                        }
                        else if (mm==7)
                        {
                        this.itemFormBon.mois_nom='Juillet'

                        }
                        else if (mm==8)
                        {
                        this.itemFormBon.mois_nom='Août'


                        }
                        else if (mm==9)
                        {
                        this.itemFormBon.mois_nom='Septembre'

                        }
                        else if (mm==10)
                        {
                        this.itemFormBon.mois_nom='Octobre'


                        }
                        else if (mm==11)
                        {
                        this.itemFormBon.mois_nom='Novembre'

                        }
                        else if (mm==12)
                        {
                        this.itemFormBon.mois_nom='Décembre'

                        }
                        else
                        {
                        this.itemFormBon.mois_nom='--';

                        }

        },

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>