<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





     
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">


                                <label for="depot_id" class="mr-sm-2 pt-2"> Dépôt :</label>

                                            <select class="form-control border rounded mr-4" id="depot_id" v-model="itemFormBon.depot_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.depot_id.$error }">

                                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            <option v-if="user_dep==0" value=0>Tous</option>

                                            </select>


                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.date_exp"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />




                                    <div class="input-group-prepend ml-4">
                                        <button class="btn  btn-outline-info rounded-pill ml-1"  id="button-addon1" @click="handleSubmitBonCMD">   
                                        <span class="fa fa-search"></span> Expirés (Non Marqués)
                                        </button>
                                    </div>


                                    <div class="input-group-prepend ml-3 mr-3">
                                        <button class="btn  btn-outline-secondary  rounded-pill ml-1"  id="button-add1" @click="handleSubmitMarq">   
                                        <span class="fa fa-search"></span> Expirés (Marqués)
                                        </button>
                                    </div>   

                </div>
   





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Lots Expirés</h4> 



                    <div class="col">

                                    <b-button @click="exportDox" pill size="sm"  class="m-3 float-right" variant="info">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>
            </div>
       



        <b-table 

        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"

        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
                  <template #cell(actions)="row">

                    <b-button pill size="sm" class="mr-2 mb-1 float-right" variant="outline-primary" @click="edit_click(row.item)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                </template>

        </b-table>

        
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {
          
      rows_pagin() {
        return this.itemslist.length
      },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },


    },
    mounted(){
 this.fetchCentre()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    date_exp:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    depot_id:'',
                    centre_nom:''
            },

            depots:[],
            itemslist: [],
            fields: [
				{key: 'ref', label: 'Référence', sortable: true},
                {key: 'depot', label: 'Dépôt', sortable: true},
                {key: 'nom', label: 'Nom Lots', sortable: true},
                {key: 'date', label: 'Création', sortable: true},
                {key: 'date_exp', label: 'Date Exp', sortable: true},
                {key: 'qte', label: 'Qté', sortable: true},
                {key: 'exp', label: 'Expiré', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                { key: 'actions', label: '' }

            ],

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                date_exp: {
                    required
                },
                depot_id: {
                    required
                }

            }

    },
    methods :{


exportDox()
        {

        let cent = ""


        if(this.itemFormBon.depot_id==0)
        {
        cent="Tous"
        }
        else
        {
        let mm=this.depots.findIndex(item => item.id === this.itemFormBon.depot_id)

        cent= this.depots[mm].nom
        }



        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Référence",
                                            spacing: {
                before: 100,
                after: 100
            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Dépôt",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Lot",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        
                                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Date Exp",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        
                                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Quantité",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })]

        this.itemslist.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.ref,
                                        spacing: {
                                        before: 50,
                                        after: 50
                                    },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.depot,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.nom,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.date_exp,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.qte.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })

        datrw.push(
        r
        )
        });

                const doc = new Document();

        const table = new Table({

            rows:  datrw
        })



                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Lots Expirés",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }), 

                                new TextRun({
                                text: "     Dépôt : "+cent,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                                new TextRun({
                                text: "     Date : "+this.itemFormBon.date_exp,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),




                        table
                        
                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "Lot_éxpités.docx");

            });

        },


                fetchCentre: function () {


                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depotPerson/'+this.user_dep)
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
edit_click(item)
{

                this.$confirm("Voulez-vous vraiment modifier l'état de ce lots ??")
                 .then(() => {
                        if(item.exp==0)
                        {
                        this.lotsEdit(item.id,1)
                        }
                        else
                        {
                        this.lotsEdit(item.id,0)
                        }
                })
                .catch(error=> {
                console.log(error)

                });





},


        lotsEdit(id,exp){

            this.isLoading=true;

            this.$http.get('/changExpairLot/'+id+'/'+exp)
                .then(response => {

                    this.isLoading=false;

                    console.log(response.data.data)


                    let index = this.itemslist.findIndex(item => item.id === id)
                    this.itemslist.splice(index, 1)


                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },

        handleSubmitBonCMD(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchList(this.itemFormBon.date_exp,0)
   

                }


        },
handleSubmitMarq(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchList(this.itemFormBon.date_exp,1)
   

                }


        },
        fetchList: function (dt,ex) {
                    this.isLoading=true;
                    
                    this.$http.get('/expairLot/'+dt+'/'+ex+'/'+this.itemFormBon.depot_id)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>