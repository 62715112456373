<template>
<div>
  




 
      <div class="rounded-lg shadow border border-white bg-white pl-4 pb-4 mt-3 overflow-auto">
            <div class="row justify-content-centre align-items-center mb-5">    

            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Soldes Des Comptes </h4> 


                    <div class="col">


                        
                                    <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>

                                    <b-button pill size="sm"  class="m-3 float-right" variant="success" @click="addVerInterne">
                                        <i class="fa fa-coins"></i>
                                        <span> Virement Interne</span> 
                                    </b-button>

                                    <b-button pill size="sm"  class="m-3 float-right" variant="warning" @click="initSolde_click">
                                        <i class="fa fa-eraser"></i>
                                        <span> Effacer Historique</span> 
                                    </b-button>

                    </div>
            </div>
       


                            <b-list-group horizontal="md" >
                            <b-list-group-item  v-for="item in itemslist" :key="item.id" button class="p-4 mr-4 ml-0" variant="primary">

                                <div style="width: 220px;">
                                  

                                <div class="d-flex justify-content-center pt-1 text-secondary">
                                    <h3 class="font-weight-light ">{{ item.nom }}  </h3>
                                </div>

                                <div class="d-flex justify-content-center pt-2 ">
                                    <h4 >{{item.total_oper}} DHs </h4>
                                </div>
</div>
                                    
                            </b-list-group-item>

                            </b-list-group>

                         
      </div>


              <div class="card border-left-success shadow mt-5">
                <div class="card-body p-0">

                        <div class=" d-flex justify-content-center pt-1 text-secondary">
                                <h4 class="font-weight-normal p-2">TOTAL : {{total_compt}} DH</h4>
                        </div>

                </div>
              </div>



    <!-- le modal -->
    <b-modal id="banqModal" :title="itemForm.nom"  hide-footer>
           <form @submit.prevent="handleSubmit">

            <div class="form-group">
                            <label for="bank_compteSourc_id">Compte Source :</label>
                            <select class="form-control" id="bank_compteSourc_id" v-model="itemForm.bank_compteSourc_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.bank_compteSourc_id.$error }">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

                <div v-if="isSubmitted && !$v.itemForm.bank_compteSourc_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>


            <div class="form-group">
                            <label for="bank_compteDestin_id">Compte Destination :</label>
                            <select class="form-control" id="bank_compteDestin_id" v-model="itemForm.bank_compteDestin_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.bank_compteDestin_id.$error }">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

                <div v-if="isSubmitted && !$v.itemForm.bank_compteDestin_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>

            <div class="form-group">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemForm.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            <div class="form-group">
                <label for="montant_oper">Montant :</label>
                <input type="text" v-model="itemForm.montant_oper" id="montant_oper" name="montant_oper" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.montant_oper.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.montant_oper.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.montant_oper.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>



            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Ajouter</button>
            </div>


        </form>

    </b-modal>
    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";


export default {
      computed: {
              user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
      total_compt: function() {
      return this.itemslist.reduce((a, b) => +a + +b.total_oper, 0);
    },

    },
    mounted(){
this.fetchList()
    },
	data () {
		return {

            comptes:[],

            itemForm: {
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    montant_oper:'',
                    user:'user',
                    bank_compteSourc_id:'',
                    bank_compteDestin_id:'',
                    bank_sourc:'',
                    bank_destin:''},
            itemslist: [],
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                date: {
                    required
                },
                montant_oper: {
                    required,
                    decimal
                },
                bank_compteSourc_id: {
                    required
                },
                bank_compteDestin_id: {
                    required
                }

            }
    },
    methods :{

        fetchList: function () {
                    this.isLoading=true;
                    
                    this.$http.get('/soldeComptRapps')
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        fetchFormComptes: function () {
            //comptes :
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;

                                           
                        this.$root.$emit('bv::show::modal', 'banqModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
       addVerInterne()
       {
            this.itemForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            this.itemForm.montant_oper='',
            this.itemForm.bank_compteSourc_id='',
            this.itemForm.bank_compteDestin_id='',



        this.fetchFormComptes()

       },
       handleSubmit() {

                //USER HANDLE
                this.itemForm.user= this.user_name


                if(this.itemForm.bank_compteSourc_id==this.itemForm.bank_compteDestin_id)
                {
                        this.itemForm.bank_compteSourc_id=''
                        this.itemForm.bank_compteDestin_id=''

                }

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                    this.itemslisttore()

                }

        },


        itemslisttore(){
            //get bank name:
                    let ss=this.comptes.findIndex(item => item.id === this.itemForm.bank_compteSourc_id)

                    this.itemForm.bank_sourc= this.comptes[ss].nom


                    let dd=this.comptes.findIndex(item => item.id === this.itemForm.bank_compteDestin_id)

                    this.itemForm.bank_destin= this.comptes[dd].nom
            //fin bankname




            this.isLoading=true;

            this.$http.post('/transfertCompts',{
            date:this.itemForm.date,
            mont:this.itemForm.montant_oper,
            id_sourc:this.itemForm.bank_compteSourc_id,
            id_destin:this.itemForm.bank_compteDestin_id,
            user:this.itemForm.user,
            description:"Virm. De "+this.itemForm.bank_sourc+" Vers "+this.itemForm.bank_destin
            })
            .then(response => {

                    this.isLoading=false;
                    this.$bvModal.hide('banqModal')
                    console.log(response)

                    this.fetchList()

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
       

        itemslisttoreType(){
                        this.isLoading=true;

            this.$http.post('/transfertCompts',{
            date:this.itemForm.date,
            mont:this.itemForm.montant_oper,
            id_sourc:this.itemForm.bank_compteSourc_id,
            id_destin:this.itemForm.bank_compteDestin_id,
            user:this.itemForm.user,
            description:"kokokoko",

                })
                .then(response => {

                    this.isLoading=false;
                    this.types.unshift(response.data.data);

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },




       initSolde_click()
       {

                    this.$confirm("Voulez-vous vraiment effacer l'historique des opèrations ET initialiser les comptes avec le solde ??")
                    .then(() => {

                            this.itemForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-')

                //USER HANDLE
                this.itemForm.user= this.user_name

                            this.initSold()
                    })
                    .catch(error=> {
                    console.log(error)

                    });






       },
      initSold(){
            this.isLoading=true;

            this.$http.get('/initSoldeCompt/'+this.itemForm.date+'/'+this.itemForm.user)
                .then(response => {

                    this.isLoading=false;
                    console.log(response)


                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },



    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>