<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-layer-group fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Rapports De Fin D'Année</h2>
</div>




<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_lait_coll">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Total Du Lait Collecté</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Annual) Card Example  -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_paiem_fourn">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Paiements / Impayés Des Fournisseurs</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
</div>

<div class=" row p-4">

              <!-- Earnings (Annual) Card Example  -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_agri_credits">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Agriculteurs Avec Crédits</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Earnings (Annual) Card Example  -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_resume_agri">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Résumé Des Paiements Agriculteurs</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
</div>



  </div>
</template>

<script>
export default {

 methods:{
      click_agri_credits()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'listAgriAvecCreditsRapp'

                    });
      },
      click_paiem_fourn()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'resumePaiemFournisRapp'

                    });
      },
       click_lait_coll()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'listLaitCollParCentreRapp'

                    });
      },
      click_resume_agri()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'listResumePaiemAgris'

                    });
      },


 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>