<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-sliders-h fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Paramètres</h2>
</div>



<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example  -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_prixLait">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Prix Du Lait</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-coins fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>



    <!-- le modal -->
    <b-modal id="banqModal" title="Prix"  hide-footer>
           <form @submit.prevent="handleSubmit">


            <div class="form-group col">
                <label for="qota_p">Quota :</label>
                <input type="text" v-model="itemForm.qota_p" id="qota_p" name="qota_p" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.qota_p.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.qota_p.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.qota_p.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>
         
            <div class="form-group col">
                <label for="sur_quota_abonp">Sur Quota (Adhérent) :</label>
                <input type="text" v-model="itemForm.sur_quota_abonp" id="sur_quota_abonp" name="sur_quota_abonp" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.sur_quota_abonp.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.sur_quota_abonp.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.sur_quota_abonp.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>


            <div class="form-group col">
                <label for="sur_quota_p">Sur Quota :</label>
                <input type="text" v-model="itemForm.sur_quota_p" id="sur_quota_p" name="sur_quota_p" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.sur_quota_p.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.sur_quota_p.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.sur_quota_p.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Modifier</button>
            </div>


        </form>
    </b-modal>



    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>



  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
export default {
data () {
		return {

            itemForm: {
                    qota_p:'',
                    sur_quota_abonp:'',
                    sur_quota_p:''},

            //public trucs

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                qota_p: {
                    required,
                    decimal
                },
                sur_quota_abonp: {
                    required,
                    decimal
                },
               sur_quota_p: {
                    required,
                    decimal
                },
            }
    },
    methods :{

      click_prixLait()
      {
                    this.isLoading=true;
                    this.$http.get('/pametres/1')
                    .then((result) => {


                        this.itemForm.qota_p=result.data.qota_p
                        this.itemForm.sur_quota_abonp=result.data.sur_quota_abonp
                        this.itemForm.sur_quota_p=result.data.sur_quota_p

                        this.isLoading=false;


                        this.$root.$emit('bv::show::modal', 'banqModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
      },

        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/pametres/1',{
            qota_p:this.itemForm.qota_p,
            sur_quota_abonp:this.itemForm.sur_quota_abonp,
            sur_quota_p:this.itemForm.sur_quota_p

                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('banqModal')

                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },

        handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


   this.banqueEdit()  

                }

            }

    },
    components: {
              Loading      
    }

}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>