<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-user-friends fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Rapports Agriculteurs / Centres</h2>
</div>




<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_lait">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Liste Lait Collecté</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list  fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

                        <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_Avances">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Liste Avances</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>





<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_services">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Liste Services</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_serviceParAgri">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Service Par Agriculteur</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
</div>





  </div>
</template>

<script>
export default {

 methods:{
       click_lait()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'ListLaitCollected'

                    });
      },
      click_services()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'listServicesRapp'

                    });
      },
      click_Avances()
      {
                               //goto first page:
                    this.$router.push({ 
                        name: 'listAvancesAgrisListRapp'

                    });
      },
      click_serviceParAgri()
      {
                               //goto first page:
                    this.$router.push({ 
                        name: 'listServiceParAgriRapp'

                    });
      }

 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>