<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <form @submit.prevent="handleSubmitBonCMD()">
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">

                            <label for="rh_departement_id" class="mr-sm-2 pt-2"> Département :</label>



                                            <select class="form-control border rounded mr-4" id="rh_departement_id" v-model="itemFormBon.rh_departement_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.rh_departement_id.$error }">

                                            <option v-for="item in departements" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            <option  value=0>Tous</option>

                                            </select>


        
                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> De :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
        

                       
                <label for="dateFin" class="mr-sm-2  pt-2 pl-1"> Au :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="itemFormBon.dateFin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
      



                                    <div class="input-group-prepend">
                                        <button class="btn  btn-info rounded-pill ml-1" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                                

                </div>
        </form>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col-3 ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Absences</h4> 

                <div class="col">
                            <h5 class="ml-5 mt-1 font-weight-lighter text-secondary"> 
                                Total Justifiés : {{total_just}}</h5>

                            <h5 class="ml-5 mt-1 font-weight-lighter text-secondary"> Total Non Justifiés : {{total_nonjust}}</h5>
                </div>

                    <div class="col-3">


                                    <b-button @click="exportDox" pill size="sm"  class="m-3 float-right" variant="info">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>
            </div>
       



        <b-table 
        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"

        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
        </b-table>

                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {

      computed: {
          
      rows_pagin() {
        return this.itemslist.length
      },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
      total_just: function() {
      return this.itemslist.reduce((a, b) => +a + +b.absen_just, 0).toFixed(2);
    },
    total_nonjust: function() {
      return this.itemslist.reduce((a, b) => +a + +b.absen_nonjust, 0).toFixed(2);
    },

    },

    mounted(){
  this.fetchCentre();
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    dateFin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    rh_departement_id:''
            },

            departements:[],
            itemslist: [],
            fields: [
				{key: 'nom_prenom', label: 'Nom ET Prénom', sortable: true},
                {key: 'absen_just', label: 'Absences Justifiés', sortable: true},
                {key: 'absen_nonjust', label: 'Absences Non Justifiés', sortable: true},

            ],

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                dateDebut: {
                    required
                },
                dateFin: {
                    required
                },
                rh_departement_id: {
                    required
                }
            }

    },
    methods :{

        exportDox()
                        {

                        let depar = ""


                        if(this.itemFormBon.rh_departement_id==0)
                        {
                        depar="Tous"
                        }
                        else
                        {
                        let mm=this.departements.findIndex(item => item.id === this.itemFormBon.rh_departement_id)
                        depar= this.departements[mm].nom
                        }



                        let datrw =[new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 4500,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Nom et Prénom",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 400,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Absences Justifiés",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 400,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Absences Non Justifiés",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                    ],
                                })]

                        this.itemslist.forEach(function(item){

                        let r = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 4500,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.nom_prenom,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 4000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.absen_just.toString(),
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 4000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.absen_nonjust.toString(),
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                    ],
                                })

                        datrw.push(
                        r
                        )
                        });

                                const doc = new Document();

                        const table = new Table({

                            rows:  datrw
                        })



                                doc.addSection({


                                    properties: {
                                        page: {
                                            pageNumbers: {
                                                start: 1,
                                                formatType: PageNumberFormat.DECIMAL,
                                            },
                                        },
                                    },
                                    headers: {
                                        default: new Header({
                                            children: [
                                                new Paragraph({
                                                    children: [
                                            new TextRun({
                                                text: "تعاونية حليب الريش",
                                                color: '636572',
                                                bold: true,

                                                })
                                                    ],
                                                border: {
                                                bottom: {
                                                        color: "636572",
                                                        space: 4,
                                                        value: "single",
                                                        size: 10,
                                                    }},
                                                    heading: HeadingLevel.TITLE,
                                                    alignment: AlignmentType.CENTER,
                                                }),
                                            ],
                                        }),
                                    },
                                    footers: {
                                        default: new Footer({
                                            children: [
                                                new Paragraph({
                                                    alignment: AlignmentType.RIGHT,
                                                    children: [
                                                        new TextRun({
                                                            children: ["Page : ", PageNumber.CURRENT],
                                                        }),
                                                        new TextRun({
                                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                        }),
                                                    ],
                                                }),
                                            ],
                                        }),
                                    },



                                    children: [

                                        new Paragraph({



                                            children: [

                                                new TextRun({
                                                text: "Rapport Des Absences",
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            })
                                            ],

                                            heading: HeadingLevel.HEADING_2,
                                                                alignment: AlignmentType.CENTER,

                                                spacing: {
                                                    after: 300,
                                                    before: 300
                                                },
                                        }),
                                        

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "     De : "+this.itemFormBon.dateDebut+" Au "+this.itemFormBon.dateFin,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),



                            ],

                            heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                        new Paragraph({



                            children: [

                                                new TextRun({
                                                text: "       Département : "+depar+"      T.Justifiés : "+this.total_just+"      T.Non Justifiés : "+this.total_nonjust,
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            })

                            ],

                            heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                            }),


                                        table
                                        
                                        ]
                                })







                            Packer.toBlob(doc).then(blob => {

                        saveAs(blob, "rapport_Absences.docx");

                            });

        },
        fetchCentre: function () {
                    //chager les zones:
                    this.isLoading=true;
                    this.$http.get('/departements')
                    .then((result) => {
                                this.departements = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        handleSubmitBonCMD(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchList(this.itemFormBon.rh_departement_id)
   

                }


        },

        fetchList: function (iddep) {
                    this.isLoading=true;
                    
                    this.$http.get('/resumAbsencEmplRapp/'+iddep+'/'+this.itemFormBon.dateDebut+'/'+this.itemFormBon.dateFin)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>