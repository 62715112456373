<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <form>
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">

                                <label for="centre_id" class="mr-sm-2 pt-2"> Centre :</label>

                                            <select class="form-control border rounded" id="centre_id" v-model="itemFormBon.centre_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.centre_id.$error }">

                                            <option v-for="item in centres" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            
                                            <option value=0>Tous</option>

                                            </select>





                                <label for="service_id" class="mr-sm-2 ml-3 pt-2"> Service :</label>

                                            <select class="form-control border rounded mr-4" id="service_id" v-model="itemFormBon.service_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.service_id.$error }">

                                            <option v-for="item in ListServices" :key="item.id" v-bind:value="item.id">   
                                                {{ item.ref }}
                                            </option>


                                            </select>



        
                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> De :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
        

                       
                <label for="dateFin" class="mr-sm-2  pt-2 pl-1"> Au :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="itemFormBon.dateFin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />


                    <b-button pill size="sm" class="m-2 mb-1 float-right" variant="outline-primary" @click="handleSubmitBonCMD(0)">
                            <i class="fa fa-search"></i>
                            Tous
                    </b-button>  

                    <b-button pill size="sm" class="m-2 mb-1 float-right" variant="outline-primary" @click="handleSubmitBonCMD(1)">
                            <i class="fa fa-search"></i>
                            Adhérents
                    </b-button>  

                </div>
        </form>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Service Par Agriculteur {{typ}}</h4> 

            <h5 class="col-4 ml-3 font-weight-lighter text-info">  Total : {{total_dh}}</h5> 

                    <div class="col-2">


                                    <b-button @click="exportDox" pill size="sm"  class="m-3 float-right" variant="info">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>
            </div>
       



        <b-table 


        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"

        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
      
        </b-table>

                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {

      rows_pagin() {
        return this.itemslist.length
      },

      total_dh: function() {
      return this.itemslist.reduce((a, b) => +a + +b.total_qte, 0);
    },

    },
    mounted(){
      this.fetchCentre()
    },
	data () {
		return {
            typ:'',
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    centre_id:'',
                    centre_nom:'',
                    dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    dateFin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    service_id:''
            },
            ListServices:[],
            centres:[],
            itemslist: [],
            fields: [
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'centre', label: 'Centre', sortable: true},
                {key: 'total_qte', label: 'Total Quantité', sortable: true},


            ],

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                centre_id: {
                    required
                },
                dateDebut: {
                    required
                },
                dateFin: {
                    required
                },
                service_id: {
                    required
                }
            }

    },
    methods :{

    exportDox()
    {


                        let dep = ""


                        if(this.itemFormBon.service_id)
                        {
                        let mm=this.ListServices.findIndex(item => item.id === this.itemFormBon.service_id)
                        dep= this.ListServices[mm].ref
                        }



                        let cent = ""


                        if(this.itemFormBon.centre_id==0)
                        {
                        cent="Tous"
                        }
                        else
                        {
                        let mm=this.centres.findIndex(item => item.id === this.itemFormBon.centre_id)

                        cent= this.centres[mm].nom
                        }



                        let datrw =[new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 4600,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Nom",
                                                            spacing: {
                                                                before: 100,
                                                                after: 100
                                                            },
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Centre",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Total Quantité",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                    ],
                                })]

                        this.itemslist.forEach(function(item){

                        let r = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 4600,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.nom,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.centre,
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.total_qte.toString(),
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                    ],
                                })

                        datrw.push(
                        r
                        )
                        });

                                const doc = new Document();

                        const table = new Table({

                            rows:  datrw
                        })



                                doc.addSection({


                                    properties: {
                                        page: {
                                            pageNumbers: {
                                                start: 1,
                                                formatType: PageNumberFormat.DECIMAL,
                                            },
                                        },
                                    },
                                    headers: {
                                        default: new Header({
                                            children: [
                                                new Paragraph({
                                                    children: [
                                            new TextRun({
                                                text: "تعاونية حليب الريش",
                                                color: '636572',
                                                bold: true,

                                                })
                                                    ],
                                                border: {
                                                bottom: {
                                                        color: "636572",
                                                        space: 4,
                                                        value: "single",
                                                        size: 10,
                                                    }},
                                                    heading: HeadingLevel.TITLE,
                                                    alignment: AlignmentType.CENTER,
                                                }),
                                            ],
                                        }),
                                    },
                                    footers: {
                                        default: new Footer({
                                            children: [
                                                new Paragraph({
                                                    alignment: AlignmentType.RIGHT,
                                                    children: [
                                                        new TextRun({
                                                            children: ["Page : ", PageNumber.CURRENT],
                                                        }),
                                                        new TextRun({
                                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                        }),
                                                    ],
                                                }),
                                            ],
                                        }),
                                    },



                                    children: [

                                        new Paragraph({



                                            children: [

                                                new TextRun({
                                                text: "Service Par Agriculteur"+this.typ,
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            }),

                                                new TextRun({
                                                text: "     De : "+this.itemFormBon.dateDebut+" Au "+this.itemFormBon.dateFin,
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            })
                                            ],

                                            heading: HeadingLevel.HEADING_2,
                                                                alignment: AlignmentType.CENTER,

                                                spacing: {
                                                    after: 300,
                                                    before: 300
                                                },
                                        }),
                                        
                                        
                                        new Paragraph({



                                            children: [ 

                                                new TextRun({
                                                text: "     Centre : "+cent+"     Service : "+dep+"         Total : "+this.total_dh,
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            })
                                            ],

                                            heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,

                                                spacing: {
                                                    after: 300,
                                                    before: 300
                                                },
                                        }),



                                        table
                                        
                                        ]
                                })







                            Packer.toBlob(doc).then(blob => {

                        saveAs(blob, "Services_centre.docx");

                            });

    },





                fetchCentre: function () {

                    //chager les ListServices:
                    this.isLoading=true;
                    this.$http.get('/articlesParTypeCateg/1')
                    .then((result) => {
                                this.ListServices = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les ListServices:
                    this.isLoading=true;
                    this.$http.get('/centres')
                    .then((result) => {
                                this.centres = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },

                handleSubmitBonCMD(abonn){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {
                    if(abonn==0)
                    {
                        this.typ=" ( Tous )"
                    }
                    else
                    {
                        this.typ=" ( Adhérents )"                        
                    }


                this.fetchList(this.itemFormBon.centre_id,this.itemFormBon.service_id,abonn,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)
   

                }


        },

        fetchList: function (idcntr,serv,abon,deb,fin) {
                    this.isLoading=true;
                    
                    this.$http.get('/listServiceParAgriRapp/'+idcntr+'/'+serv+'/'+abon+'/'+deb+'/'+fin)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>